import { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import styles from './styles/global.module.css';

import { Header, MobileMenuModal } from './components';
import { About, Work, Projects, Contact } from './pages';
import { getWindowWidth } from './utils/functions';
import { mobileBreakpoint } from './utils/constants';

export default function App() {
  const width = getWindowWidth();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <Router>
      <div className={styles.container}>
        <MobileMenuModal
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          isVisible={isMobileMenuOpen && width <= mobileBreakpoint}
        />
        <Header
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
        <div className={styles.body}>
          <Routes>
            <Route path='/' element={<Navigate to='about' />} />
            <Route path='about' element={<About />} />
            <Route path='work' element={<Work />} />
            <Route path='projects' element={<Projects />} />
            <Route path='contact' element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
