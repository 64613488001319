import { Link } from 'react-router-dom';

import styles from './mobile-menu-modal.module.css';

export default function MobileMenuModal({ isVisible, setIsMobileMenuOpen }) {
  const handleMobileMenuClick = () => setIsMobileMenuOpen(false);

  return (
    <div
      className={
        isVisible ? styles.visibleContainer : styles.invisibleContainer
      }
    >
      {isVisible && (
        <div>
          <Link
            style={{ color: 'white' }}
            to='/about'
            onClick={handleMobileMenuClick}
          >
            About
          </Link>

          <Link to='/work' onClick={handleMobileMenuClick}>
            work
          </Link>

          <Link to='/projects' onClick={handleMobileMenuClick}>
            projects
          </Link>

          <Link to='/contact' onClick={handleMobileMenuClick}>
            contact
          </Link>
        </div>
      )}
    </div>
  );
}
