import React from 'react';
import { Link } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';

import { getWindowWidth } from '../../utils/functions';
import { mobileBreakpoint } from '../../utils/constants';
import styles from './header.module.css';

export default function Header({ isMobileMenuOpen, setIsMobileMenuOpen }) {
  const pageName = window.location.pathname.substring(1);
  const width = getWindowWidth();

  const handleMobileMenuClick = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <div className={styles.headerContainer}>
      {pageName}
      {width > mobileBreakpoint ? (
        <div className={styles.headerNav}>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/work'>work</Link>
            </li>
            <li>
              <Link to='/projects'>projects</Link>
            </li>
            <li>
              <Link to='/contact'>contact</Link>
            </li>
          </ul>
        </div>
      ) : (
        <>
          {isMobileMenuOpen ? (
            <button onClick={handleMobileMenuClick}>
              <IoClose className={styles.closeIcon} />
            </button>
          ) : (
            <button onClick={handleMobileMenuClick}>
              <BsThreeDotsVertical className={styles.menuIcon} />
            </button>
          )}
        </>
      )}
    </div>
  );
}
