function Work() {
  return (
    <div>
      <h1>work</h1>
      {/* <p>// some of my work experience</p> */}

      <h2>Princeton Korean Community Church</h2>
      <p>Associate Pastor</p>

      <h2>Young Life</h2>
      <p>Mission Staff</p>

      <h2>Ephatha Mental Health Associates</h2>
      <p>Medical Billing Manager</p>

      <h2>Central Park Taekwondo</h2>
      <p>Instructer/Coach</p>
    </div>
  );
}

export default Work;
