import imgPlaceholder from '../assets/imgPlaceholder.png';

function Projects() {
  return (
    <div>
      <h1>Projects</h1>
      {/* <p> // some of the projects I made</p> */}

      <ul className='project-container'>
        <li className='project-image'>
          <img src={imgPlaceholder} alt={imgPlaceholder} />
        </li>
        <li className='project-title'>
          <h2>Bill Splitter</h2>
          <p>
            Mobile optimized web app that can take pictures of restaurant bills
            and split the total cost exactly based on individuals’ orders
          </p>
        </li>

        <li className='project-image'>
          <img src={imgPlaceholder} alt={imgPlaceholder} />
        </li>
        <li className='project-title'>
          <h2>Michellewaugh.com</h2>
          <p>E-commerce website for New York fashion designer Michelle Waugh</p>
        </li>

        <li className='project-image'>
          <img src={imgPlaceholder} alt={imgPlaceholder} />
        </li>
        <li className='project-title'>
          <h2>Mise En++</h2>
          <p>
            Web app used to visually organize the cooking steps of meals
            assembled from recipes
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Projects;
